<template>
  <b-row class="px-2 justify-content-center">
    <b-col cols="12" lg="6" class="card-content mt-3">
      <h4 class="primary fw-bold text-center">Queremos confirmar que seas tú</h4>
      <p class="mt-4 fs-14" align="justify">
        Enviamos un código OTP a tu número de celular registrado terminado en ******<strong>{{ celular }}</strong>
        ingrésalo en el siguiente campo para validar tu identidad:
      </p>
      <b-row class="pb-3">
        <b-col cols="12" class="text-center">
          <p class="fs-12 primary fw-bold mb-1">
            Ingresa aquí el código que llega a tu celular
          </p>
          <i class="las la-arrow-down icon-menu primary py-1"></i>
          <div class="mb-2 content-center">
            <b-form-input
              class="w-input-code"
              @keypress="onlyNumber"
              v-model="$v.codigo.$model"
              :state="$v.codigo.$dirty ? !$v.codigo.$error : null"
            />
          </div>
        </b-col>
        <b-col cols="12" class="text-center">
          <b-button variant="danger" @click="validarCodigo()"> <i class="las la-check-circle" /> Ingresar </b-button>
          <b-button class="mx-2" variant="outline-danger" @click="reenviarCodigo()" :disabled="contador > 0">
            <i class="las la-paper-plane" /> Reenviar Código {{ contador > 0 ? `(${contador})` : "" }}
          </b-button>
        </b-col>
      </b-row>
      <p class="mt-2 fs-14" align="justify">
        Si no es tu número registrado o cambiaste de línea de celular, comunícate con nosotros y actualiza tus datos a
        través de nuestras líneas de Servicio al Cliente:
      </p>
      <p class="mt-2 mb-0 primary"><i class="las la-phone dark" /> {{ numeroUnicoColombia }}</p>
      <p class="primary"><i class="lab la-whatsapp color-whatsapp" /> {{ numeroWhastappCartera }}</p>
    </b-col>
  </b-row>
</template>

<script>
import PublicService from "@/app/core/api/public";
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import AlertsService from "@/app/shared/services/alerts.service";
import { required, numeric, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  mixins: [mixinsEvents],
  data() {
    return {
      contador: 0,
      codigo: null,
      celular: null,
      formInfo: null,
      intervalContador: null,
      tiempoHabilitarReenvio: 0,
    };
  },
  computed: {
    numeroWhastappCartera() {
      return this.$store.getters.infoContactoProgreser.whatsappCartera;
    },
    numeroUnicoColombia() {
      return this.$store.getters.infoContactoProgreser.numeroUnicoColombia;
    },
  },
  validations: {
    codigo: { required, numeric, minLength: minLength(4), maxLength: maxLength(4) },
  },
  created() {
    if (this.$store.getters.isLogin) {
      this.formInfo = { cedula: this.$store.getters.identificacion, celular: this.$store.getters.numeroCelular };
    } else this.formInfo = { ...this.$store.getters.dataShared };

    this.celular = this.formInfo.celular.substr(this.formInfo.celular.length - 4, 4);
    this.generarCodigo();
  },
  methods: {
    iniciarContador() {
      this.contador = this.tiempoHabilitarReenvio;
      this.intervalContador = setInterval(() => {
        if (this.contador > 0) {
          this.contador -= 1;
        } else {
          clearInterval(this.intervalContador);
        }
      }, 1000);
    },
    generarCodigo() {
      const body = {
        estado: 3,
        tipo: "SMS",
        cedula: this.formInfo.cedula,
        celular: this.formInfo.celular,
        idSolicitud: Math.round(Math.random() * 999999),
      };

      PublicService.generarCodigoActualizarDatos(body)
        .then((response) => {
          if (response.data.estado) {
            this.tiempoHabilitarReenvio = response.data.tiempoHabilitarReenvio;
            this.iniciarContador();
            AlertsService.success("Actualizar Datos", response.data.mensaje);
          }
        })
        .catch(() => {
          this.$router.push("/home");
        });
    },
    validarCodigo() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const body = { codigoOTP: this.codigo, cedula: this.formInfo.cedula };

      PublicService.validarCodigoActualizarDatos(body).then((response) => {
        AlertsService[response.data.estado ? "success" : "error"]("Actualizar Datos", response.data.mensaje);
        if (response.data.estado) {
          this.$emit("nextStep");
        }
        if (response.data.cerrarModal) this.$router.push("/home");
      });
    },
    reenviarCodigo() {
      const body = { cedula: this.formInfo.cedula, idSolicitud: 0, estado: 1, tipo: "1" };

      PublicService.reenviarCodigoActualizarDatos(body).then((response) => {
        if (response.data.estado) {
          this.tiempoHabilitarReenvio = response.data.tiempoHabilitarReenvio;
          this.iniciarContador();
          AlertsService.success("Cambio de contraseña", response.data.mensaje);
        }
      });
    },
  },
};
</script>
