import http from "@/app/core/config/axios.config";

export default class ValidacionIdentidadService {
  /* GENERACION CODIGOS ------------------------------------------------------------------------------------------------------- */

  static generarCodigoValidacionPago(entry) {
    return http.post("/GenerarOTP/GenerarCodigo", entry);
  }

  static validarCodigoValicacionPago(entry) {
    return http.post("/GenerarOTP/ValidarCodigo", entry);
  }

  static reenviarCodigoValidacionPago(entry) {
    return http.post("/GenerarOTP/GenerarReenvioCodigo", entry);
  }

  /* GOBIERNO DATOS ----------------------------------------------------------------------------------------------------------- */

  static validarCelular(entry) {
    return http.post("/ValidacionIdentidad/GobiernoDatos", entry);
  }

  /* AUTENTICACION ------------------------------------------------------------------------------------------------------------ */

  static getDatosCliente(entry) {
    return http.post("/ValidacionIdentidad/InfoCliente/ConsultaDatos", entry);
  }

  /* PREGUNTAS ---------------------------------------------------------------------------------------------------------------- */

  static getPreguntas(entry) {
    return http.post("/ValidacionIdentidad/Preguntas/RegistroPreguntas", entry);
  }

  static validarPreguntas(entry) {
    return http.post("/ValidacionIdentidad/Preguntas/Respuestas", entry);
  }
}
