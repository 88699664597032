<template>
  <b-row class="px-2">
    <b-col cols="12">
      <h4 class="primary fw-bold">Actualiza tus datos personales</h4>
      <p class="mt-3 fs-14 text-justify">
        Ten en cuenta que la información de correo electrónico y número de celular deben ser reales, haremos el envío de
        un mensaje a estos para validar tu identidad y realizar de manera exitosa la actualización de tus datos en el
        sistema.
      </p>
    </b-col>
    <b-col cols="12" class="pb-4">
      <b-form-row>
        <b-col cols="12" md="6">
          <b-form-group label="Celular *" class="mb-1">
            <b-form-input
              @change="validarCelular"
              v-mask="['(###) ###-####']"
              :disabled="!isEditarCelular"
              v-model="$v.formAD.celular.$model"
              :state="$v.formAD.celular.$dirty ? !$v.formAD.celular.$error : null"
            />
            <b-form-invalid-feedback>{{ validarCampo($v.formAD.celular) }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-checkbox class="primary mb-2" v-model="isEditarCelular">
            Editar número de celular
          </b-form-checkbox>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Correo electrónico *">
            <b-form-input
              v-model="$v.formAD.email.$model"
              :state="$v.formAD.email.$dirty ? !$v.formAD.email.$error : null"
            />
            <b-form-invalid-feedback>{{ validarCampo($v.formAD.email) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Departamento *">
            <b-form-select
              @change="getCiudadesByDpto"
              :options="list.codigoDepartamento"
              v-model="$v.formAD.codigoDepartamento.$model"
              :state="$v.formAD.codigoDepartamento.$dirty ? !$v.formAD.codigoDepartamento.$error : null"
            >
              <template #first>
                <b-form-select-option :value="null">Seleccione</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback>{{ validarCampo($v.formAD.codigoDepartamento) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Ciudad *">
            <b-form-select
              :options="list.codigoMunicipio"
              v-model="$v.formAD.codigoMunicipio.$model"
              :state="$v.formAD.codigoMunicipio.$dirty ? !$v.formAD.codigoMunicipio.$error : null"
            >
              <template #first>
                <b-form-select-option :value="null">Seleccione</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback>{{ validarCampo($v.formAD.codigoMunicipio) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Dirección *">
            <b-form-input
              v-model="$v.formAD.direccion.$model"
              :state="$v.formAD.direccion.$dirty ? !$v.formAD.direccion.$error : null"
            />
            <b-form-invalid-feedback>{{ validarCampo($v.formAD.direccion) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" class="content-start-center pt-3 pl-3">
          <b-form-checkbox class="primary" v-model="readPoliticas">
            Acepto política de tratamiento de datos
            <a class="danger" target="_blank" href="static/Tratamiento_de_datos.pdf">Ver políticas</a>
          </b-form-checkbox>
        </b-col>
      </b-form-row>
    </b-col>
    <b-col cols="12" class="content-end-center">
      <b-button variant="danger" @click="actualizarDatos"> <i class="las la-check-circle" /> Continuar </b-button>
    </b-col>
  </b-row>
</template>

<script>
import PublicService from "@/app/core/api/public";
import ComunesService from "@/app/core/api/comunes";
import { required, email } from "vuelidate/lib/validators";
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import AlertsService from "@/app/shared/services/alerts.service";
import ValidacionIdentidadService from "@/app/core/api/validacion-identidad";

export default {
  mixins: [mixinsEvents],
  data() {
    return {
      origenDatos: null,
      celularValido: true,
      readPoliticas: false,
      isEditarCelular: false,
      list: { codigoMunicipio: [], codigoDepartamento: [] },
      formAD: {
        email: null,
        cedula: null,
        celular: null,
        direccion: null,
        codigoMunicipio: null,
        codigoDepartamento: null,
      },
    };
  },
  validations: {
    formAD: {
      celular: { required },
      direccion: { required },
      email: { required, email },
      codigoMunicipio: { required },
      codigoDepartamento: { required },
    },
  },
  created() {
    const { isLogin, identificacion, numeroCelular, dataShared } = this.$store.getters;
    if (isLogin) this.formAD = { ...this.formAD, cedula: identificacion, celular: numeroCelular };
    else this.formAD = { ...this.formAD, cedula: dataShared.cedula, celular: dataShared.celular };

    this.getDatosCliente();
    this.getListas();
  },
  methods: {
    getDatosCliente() {
      const { isLogin, tipoDocumento, dataShared } = this.$store.getters;
      const body = {
        numeroDocumento: this.formAD.cedula,
        tipoDocumento: isLogin ? tipoDocumento : dataShared.tipoDocumento,
      };

      ValidacionIdentidadService.getDatosCliente(body).then((response) => {
        this.origenDatos = response.data.origenDatos;
      });
    },
    getListas() {
      ComunesService.getDepartamentos().then((response) => {
        this.list.codigoDepartamento = response.data.map((item) => ({ text: item.nombre, value: item.codigo }));
      });
    },
    getCiudadesByDpto(value) {
      this.formAD = { ...this.formAD, codigoMunicipio: null };
      this.list = { ...this.list, codigoMunicipio: [] };
      ComunesService.getCiudadesByDpto(value).then((response) => {
        this.list.codigoMunicipio = response.data.map((item) => ({ text: item.nombre, value: item.codigo }));
      });
    },
    validarCelular() {
      if (!this.celularValido) this.celularValido = true;

      if (!this.$v.formAD.celular.$error) {
        const body = {
          origenDatos: this.origenDatos,
          numeroDocumento: this.formAD.cedula,
          celular: this.formatCelular(this.formAD.celular),
        };
        ValidacionIdentidadService.validarCelular(body).then((response) => {
          if (!response.data.resultado) {
            AlertsService.error("Actualizar Datos", response.data.mensaje);
            this.celularValido = false;
          }
        });
      }
    },
    actualizarDatos() {
      this.$v.$touch();
      if (this.$v.$invalid) return AlertsService.error("Actualizar Datos", "Debe completar los campos obligatorios.");
      if (!this.readPoliticas)
        return AlertsService.error("Actualizar Datos", "Debe aceptar la politica de tratamiento de datos.");

      const { isLogin, numeroCelular, dataShared, vigenciaHashTime } = this.$store.getters;

      const celularOriginal = this.formatCelular(isLogin ? numeroCelular : dataShared.celular);
      const celularEditado = this.formatCelular(this.formAD.celular);
      const body = { ...this.formAD, celular: celularEditado, url: `${location.origin}/login` };

      if (celularOriginal !== celularEditado) {
        this.$store.commit("updateDataShared", { celular: celularEditado, formActuDatos: body });
        this.$emit("nextStep");
      } else {
        PublicService.actualizarDatosUsuario(body).then((response) => {
          AlertsService.success("Actualizar Datos", response.data.mensaje);
          if (!isLogin) {
            const dataModal = {
              tipo: "success",
              titulo: "¡Estás a un paso de terminar tu actualización en la Sucursal Virtual ProgreSER!",
              mensaje: `Enviamos a tu correo electrónico un enlace para que actives tu cuenta, este se encontrará disponible durante ${vigenciaHashTime} minutos.`,
            };
            this.$router.push("/login");
            this.$store.commit("openModalConfirms", dataModal);
          } else {
            this.$store.commit("setUserData", { correoElectronico: this.formAD.email });
            this.$router.push("/home");
          }
        });
      }
    },
  },
};
</script>
